<template>
  <div :class="{'nav-open': $sidebar.showSidebar}">
    <!-- notificationを最前面に出すために、positionとz-indexを指定-->
    <router-view name="header" />
    <transition
      mode="out-in"
      name="fade">
      <router-view />
    </transition>
    <router-view name="footer" />
  </div>
</template>

<script>
// Loading some plugin css asynchronously
import 'sweetalert2/dist/sweetalert2.css'
import 'vue-notifyjs/themes/default.css'

export default {}
</script>
<style lang="scss">

</style>
