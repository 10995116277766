<template>
  <div class="user container-fluid">
    <div class="ml-2">
      <!-- RP name -->
      <div class="row col-sm-12 s-name">
        <span class="text-truncate">{{ $store.state.selectedRP.name }}</span>
      </div>

      <!-- RP info -->
      <table class="s-info s-rp-info mt-2">
        <tbody>
        <tr>
          <td>TYPE</td>
          <td>{{ $store.getters.serviceName($store.state.service) }}</td>
        </tr>
        <tr>
          <td>PLAN</td>
          <td>{{ capitalizeFirstLetter($store.state.selectedPlan.category) }}</td>
        </tr>
        <tr>
          <td>USERS</td>
          <td>{{ $store.state.selectedRP.user_count | separateDigit3 }}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      isClosed: true,
      idTokenClaims: this.$store.state.idTokenClaims
    }
  },
  methods: {}
}
</script>
<style lang="scss" scoped>
@import "src/assets/sass/paper/_variables.scss";

.s-name {
  color: white;
  font-weight: bold;
  font-size: 1.2em;
}

.s-rpid {
  color: $dark-gray;
  font-size: 0.734em;
  font-family: $font-family-monospace;
  //font-weight: bold;
}

.s-id-length {
  max-width: 160px;
}

.s-info {
  color: $dark-gray;
  font-size: 0.9em;
  border: none;
  //font-weight: bold;
  td {
    padding-right: 10px;
    padding-bottom: 0;
  }
}

.nav.nav-menu {
  margin-top: 0;
}

</style>
