<template>
  <div :class="[
       {'input-group': hasIcon},
       {'has-danger': error},
       {'input-group-focus': focused},
       {'has-label': label || $slots.label},
       {'has-success': hasSuccess}]"
       class="form-group">
    <slot name="label">
      <label v-if="label" :class="labelClasses">
        {{ label }}
        <span v-if="required" class="text-danger">*</span>
      </label>
    </slot>


    <div v-if="addonLeftIcon || $slots.addonLeft" class="input-group-prepend">
      <span class="input-group-text">
        <slot name="addonLeft">
          <i :class="addonLeftIcon"></i>
        </slot>
      </span>
    </div>
    <slot>
      <input
        v-bind="$attrs"
        v-on="listeners"
        :class="[{'is-valid': hasSuccess}, inputClasses, {'is-invalid': error}]"
        :readonly="readonly"
        :required="required"
        :style="inputCss"
        :value="value"
        aria-describedby="addon-right addon-left"
        class="form-control">
    </slot>
    <div v-if="addonRightIcon || $slots.addonRight"
         v-clipboard:copy="value"
         v-clipboard:success="onCopy"
         class="input-group-append"
         v-on:mouseenter="resetCopyMsg"
    >
      <span v-tooltip="{content: copyMsg, delay: {hide: 300}, hideOnTargetClick: false}"
            :style="iconStyle"
            class="input-group-text"
            style="cursor:pointer;">
        <slot name="addonRight">
          <i class="far fa-copy" style="color:#33B8F0"></i>
        </slot>
      </span>
    </div>

    <slot name="infoBlock"></slot>
    <slot name="helpBlock">
      <div v-if="error" :class="{'mt-2': hasIcon}" class="invalid-feedback error-text">
        {{ error }}
      </div>
    </slot>
  </div>
</template>
<script>
import VueClipboard from 'vue-clipboard2'
import Vue from 'vue'

VueClipboard.config.autoSetContainer = true
Vue.use(VueClipboard)

export default {
  inheritAttrs: false,
  name: 'fg-input',
  props: {
    required: {
      type: Boolean,
      description: 'Whether input is required (adds an asterix *)'
    },
    label: {
      type: String,
      description: 'Input label (text before input)'
    },
    error: {
      type: String,
      description: 'Input error (below input)'
    },
    labelClasses: {
      type: String,
      description: 'Input label css classes'
    },
    inputClasses: {
      type: String,
      description: 'Input css classes'
    },
    value: {
      type: [String, Number],
      description: 'Input value'
    },
    addonRightIcon: {
      type: String,
      description: 'Addon right icon'
    },
    addonLeftIcon: {
      type: String,
      description: 'Addont left icon'
    },
    readonly: {
      type: Boolean,
      description: 'Readonly or not'
    },
    inputCss: {
      type: String,
      description: 'Style of input'
    }
  },
  data() {
    return {
      touched: false,
      focused: false,
      hadError: false,
      copyMsg: "Copy to clipboard",
      iconStyle: "",
    }
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: this.updateValue,
        focus: this.onFocus,
        blur: this.onBlur
      }
    },
    hasSuccess() {
      return this.hadError && this.touched && !this.error
    },
    hasIcon() {
      const {addonRight, addonLeft} = this.$slots
      return addonRight !== undefined || addonLeft !== undefined || this.addonRightIcon !== undefined || this.addonLeftIcon !== undefined
    }
  },
  methods: {
    updateValue(evt) {
      let value = evt.target.value
      if (!this.touched && value) {
        this.touched = true
      }
      this.$emit('input', value)
    },
    onFocus(value) {
      this.focused = true;
      this.$emit('focus', value);
    },
    onBlur(value) {
      this.focused = false;
      this.$emit('blur', value);
    },
    onCopy: function (e) {
      this.copyMsg = "Copied!"
    },
    resetCopyMsg() {
      this.copyMsg = "Copy to clipboard"
    }
  },
  mounted() {
    if (this.readonly) {
      this.iconStyle = "background-color: #E3E3E3"
    }
  },
  created() {
    this.$watch('error', (newVal) => {
      if (newVal) {
        this.hadError = true;
      }
    }, {immediate: true})
  }
}
</script>
<style lang="scss" scoped>
.error-text {
  display: block;
}

@mixin placeholderColor($color) {
  &:placeholder-shown {
    color: $color;
  }
  &::-webkit-input-placeholder {
    color: $color;
  }
  &:-moz-placeholder {
    color: $color;
    opacity: 1;
  }
  &::-moz-placeholder {
    color: $color;
    opacity: 1;
  }
  &:-ms-input-placeholder {
    color: $color;
  }
}

input {
  @include placeholderColor(#DDDDDD);
}
</style>
