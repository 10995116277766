import DashboardLayout from '../components/Layout/DashboardLayout.vue'

// Auth guard
import {authGuard} from "../auth";

// Startup
const StartupPage = () => import('src/components/Pages/StartupPage');

// Account
const Account = () => import('src/components/Pages/Account/Account.vue');

// Sidebar menu
const Dashboard = () => import('src/components/Pages/Dashboard');
const Configurations = () => import('src/components/Pages/Configurations/Configurations');
const Users = () => import('src/components/Pages/Users/Users');
const Metadata = () => import('src/components/Pages/Metadata/LocalMetadata');
const TrustedMetadata = () => import('src/components/Pages/Metadata/TrustedMetadata');
const Plan = () => import('src/components/Pages/Plan/Plan');
const Security = () => import('src/components/Pages/Security');
const Logs = () => import('src/components/Pages/Logs/Logs');
const RegWebhooks = () => import('src/components/Pages/Webhook/RegWebhooks');
const AuthWebhooks = () => import('src/components/Pages/Webhook/AuthWebhooks');

// Error
const NotSupportedBrowser = () => import('src/components/Pages/Error/NotSupportedBrowser');


const routes = [
  {
    beforeEnter: authGuard,
    path: '/startup',
    name: 'startup',
    component: StartupPage
  },
  {
    beforeEnter: authGuard,
    path: '/',
    component: DashboardLayout,
    redirect: '/dashboard',
    children: [
      {
        path: '/account',
        name: 'Account',
        component: Account
      },
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard
      },
      {
        path: '/configurations',
        name: 'Configurations',
        component: Configurations
      },
      {
        path: '/users',
        name: 'Users',
        component: Users
      },
      {
        path: '/metadata',
        name: 'Metadata',
        component: Metadata
      },
      {
        path: '/trusted_metadata',
        name: 'TrustedMetadata',
        component: TrustedMetadata
      },
      {
        path: '/plan',
        name: 'Plans',
        component: Plan
      },
      {
        path: '/logs',
        name: 'Logs',
        component: Logs
      },
      {
        path: '/security',
        name: 'Security',
        component: Security
      },
      {
        path: '/auth_webhooks',
        name: 'AuthWebhooks',
        component: AuthWebhooks
      },
      {
        path: '/reg_webhooks',
        name: 'RegWebhooks',
        component: RegWebhooks
      },
    ]
  },
  {
    path: '/not_supported_browser',
    name: 'NotSupportedBrowser',
    component: NotSupportedBrowser
  },
  {path: '*', redirect: '/dashboard'}
];

export default routes
