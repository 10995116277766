<template>
  <!-- Classic Modal -->
  <modal
    :show-close="showClose"
    :show.sync="showModal"
    header-classes="justify-content-center"
    size="middle"
    @close="$emit('close')">
    <h4
      slot="header"
      class="title">
      Register new relying party
    </h4>

    <!-- Name -->
    <div class="row">
      <label class="col-sm-2 col-form-label">Name</label>
      <div class="col-sm-10">
        <fg-input
          v-model="model.name"
          v-validate="modelValidations.name"
          :error="getError('name')"
          name="name"
          placeholder="Relying party name"
          type="text" />
      </div>
    </div>


    <!-- Description -->
    <div class="row mt-2">
      <label class="col-sm-2 col-form-label">Description</label>
      <div class="col-sm-10">
        <fg-textarea
          v-model="model.description"
          v-validate="modelValidations.description"
          :error="getError('description')"
          class="q-form-control"
          name="description"
          placeholder="Add a description in less than 200 charactors"
          rows="2" />
      </div>
    </div>
    <div class="row">
      <label class="col-sm-2 col-form-label" />
      <div class="col-sm-10">
        <p class="text-muted">
          A free text description of the application. Max character count is 200.
        </p>
      </div>
    </div>


    <!-- FIDO specification -->
    <hr class="style1">
    <p class="text-muted mb-3">
      Please select FIDO specification your service is required.<br>
    </p>
    <div class="row">
      <div class="col-4">
        <label>
          <input
            id="webauthn"
            v-model="model.service"
            checked
            class="card-input-element d-none"
            name="service"
            type="radio"
            value="webauthn">
          <card
            class="items"
            type="pricing">
            <span
              v-if="model.service === 'webauthn'"
              class="badge badge-primary w-75">Selecting</span>
            <span
              v-else
              class="badge badge-secondary w-75">Available</span>
            <div class="card-icon icon-primary">
              <img
                alt=""
                class="s-logo"
                src="https://s3-ap-northeast-1.amazonaws.com/quado-assets/fido2_logo.svg">
            </div>
            <h6 class="s-product-title">FIDO2</h6>
            <ul>
              <li>Web apps</li>
            </ul>
          </card>
        </label>
      </div>

      <div class="col-4">
        <label>
          <input
            id="uaf"
            v-model="model.service"
            class="card-input-element d-none"
            name="service"
            type="radio"
            value="uaf">
          <card
            class="items"
            type="pricing">
            <span
              v-if="model.service === 'uaf'"
              class="badge badge-primary w-75">Selecting</span>
            <span
              v-else
              class="badge badge-secondary w-75">Available</span>
            <div class="card-icon icon-primary ">
              <img
                alt=""
                class="s-logo"
                src="https://s3-ap-northeast-1.amazonaws.com/quado-assets/uaf_logo.svg">
            </div>
            <h6 class="s-product-title">UAF</h6>
            <ul>
              <li>Mobile apps</li>
              <li>Desktop apps</li>
            </ul>
          </card>
        </label>
      </div>

      <div class="col-4">
        <label>
          <input
            id="u2f"
            v-model="model.service"
            class="card-input-element d-none"
            name="service"
            type="radio"
            value="u2f">
          <card
            class="items"
            type="pricing">
            <span
              v-if="model.service === 'u2f'"
              class="badge badge-primary w-75">Selecting</span>
            <span
              v-else
              class="badge badge-secondary w-75">Available</span>
            <div class="card-icon icon-primary ">
              <img
                alt=""
                class="s-logo"
                src="https://s3-ap-northeast-1.amazonaws.com/quado-assets/u2f_logo.svg">
            </div>
            <h6 class="s-product-title">U2F</h6>
            <ul>
              <li>Web apps</li>
              <li>&nbsp;</li>
            </ul>
          </card>
        </label>
      </div>
    </div>

    <template slot="footer">
      <p-button
        :disabled="isProcessing()"
        link
        type="default"
        @click="createRP()">
        Register
      </p-button>
    </template>
  </modal>
</template>

<script>
import {Modal} from 'src/components/UIComponents'

export default {
  name: 'PCreateRp',
  components: {
    Modal,
  },
  props: {
    showClose: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      showModal: true,
      modalSize: 'small',
      model: {
        name: '',
        description: '',
        service: 'webauthn'
      },
      modelValidations: {
        name: {
          required: true,
          max: 64
        },
        description: {
          max: 200
        },
      }
    }
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName)
    },
    createRP() {
      this.$validator.validateAll().then(isValid => {
        if (isValid) {
          const rpParams = {
            name: this.model.name,
            description: this.model.description,
          };
          this.startProcessing()
          this.$store.dispatch('createRPAction', {service: this.model.service, rpParams: rpParams}).then(() => {
            this.notifySuccess('Relying party was successfully created.')
            this.$emit('close')
          }).catch(error => {
            this.notifyErrorFormatted('Failed to create relying party.', error)
          }).finally(() => {
            this.endProcessing()
          })
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
label {
  width: 100%;
  font-size: 1rem;
}

.s-logo {
  width: 65%;
}

.s-product-title {
  margin: 15px 0 0 0;
}

.card-input-element + .items {
  outline: solid 0.5px lightgrey;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 4px;
  height: 260px;
}

.card-input-element + .items:hover {
  cursor: pointer;
}

.card-input-element:disabled + .items {
  cursor: not-allowed;
}

.card-input-element:checked + .items {
  outline: 2px solid var(--info);
  -webkit-transition: border .3s;
  -o-transition: border .3s;
  transition: border .3s;
}


@-webkit-keyframes fadeInCheckbox {
  from {
    opacity: 0;
    -webkit-transform: rotateZ(-20deg);
  }
  to {
    opacity: 1;
    -webkit-transform: rotateZ(0deg);
  }
}

@keyframes fadeInCheckbox {
  from {
    opacity: 0;
    transform: rotateZ(-20deg);
  }
  to {
    opacity: 1;
    transform: rotateZ(0deg);
  }
}
</style>
