import axios from 'axios';
import {getInstance} from "@/auth";

export default class UserService {

  constructor() {
    this.endpoint = process.env.VUE_APP_API_ENDPOINT + "/api/v1";
  }

  // baseURLを変更したいときは、URLにフルパスを指定
  async req() {
    const accessToken = await getInstance().auth0Client.getTokenSilently()
    return axios.create({
      baseURL: `${this.endpoint}`,
      timeout: 10000, // ms
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      }
    })
  }

  async showUser() {
    return (await this.req()).get('/')
  }

  async getProfile() {
    return (await this.req()).get(`/user_metadata`)
  }

  async updateProfile(userData) {
    return (await this.req()).patch(`/user_metadata`, userData)
  }

  async deleteUserFromServer() {
    return (await this.req()).delete('/')
  }

  async getAuthenticator() {
    return (await this.req()).get(`/authenticator`)
  }

  async deleteAuthenticator() {
    return (await this.req()).delete(`/authenticator`)
  }
}
