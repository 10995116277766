<template>
  <!--<div class="container-fluid">-->
  <div class="container s-pcontent">
    <!-- Header -->
    <div class="row s-page-title">
      <slot name="title" />
    </div>

    <div
      v-if="hasDescriptionSlot"
      class="row mt-1">
      <p class="s-description">
        <slot name="description" />
      </p>
    </div>

    <!-- loading image -->
    <div
      v-if="!isReady"
      class="row justify-content-center text-secondary mt-5">
      <div
        class="spinner-border"
        role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>

    <!-- Card -->
    <div
      v-if="isReady"
      class="row mt-2">
      <card
        v-if="hasDefaultSlot"
        class="w-100 s-card">
        <slot />
      </card>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PContent',
  props: {
    isReady: {
      type: [Boolean]
    },
  },
  computed: {
    hasDefaultSlot() {
      return this.$slots.default;
    },
    hasDescriptionSlot() {
      return this.$slots.description;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/sass/paper/_variables.scss";

/deep/ .s-card {
  padding: 10px 25px 25px 25px;
}

.s-rp-info {
  background-color: lightgrey !important;
  color: gray !important;
  border: none;
  font-weight: bold;
}

.s-pcontent {
  min-width: 890px;
}

.s-page-title {
  font-size: 1.8em !important;
  color: $default-color;
}

.s-description {
  color: $default-color;
}
</style>
