<template>
  <div class="content">
    <transition mode="out-in" name="fade">
      <!-- keyをつけることで、RPの切替時に同一URLへルーティングされたときに、強制的にmountedを発生させる -->
      <router-view :key="$store.state.selectedRP.id" />
    </transition>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.fade-enter-active, .fade-leave-active {
  transition-property: opacity;
  transition-duration: .25s;
}

.fade-enter-active {
  transition-delay: .25s;
}

.fade-enter, .fade-leave-active {
  opacity: 0
}
</style>
