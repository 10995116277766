export default {
  methods: {
    notifySuccess: function (message) {
      this.$notify(
        {
          group: 'main',
          type: 'Info',
          title: 'Success',
          text: message
        })
    },
    notifyError: function (message) {
      this.$notify(
        {
          group: 'main',
          type: 'error',
          title: message,
          duration: 10000,
        })
    },
    notifyErrorFormatted: function (frontMessage, axiosError) {
      let serverMessage = axiosError.response.data.message || axiosError.response.data.error_message
      if (typeof axiosError.response.status !== 'undefined' && typeof serverMessage !== 'undefined') {
        this.$notify(
          {
            group: 'main',
            type: 'error',
            title: frontMessage,
            duration: 10000,
            text: serverMessage,
          })
      } else {
        this.$notify(
          {
            group: 'main',
            type: 'error',
            title: frontMessage,
            duration: 10000,
          })
      }
    },
    notifyWarn: function (message) {
      this.$notify(
        {
          group: 'main',
          type: 'warn',
          title: 'Warn',
          text: message
        })
    },
  }
}
