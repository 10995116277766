<template>
  <SlideYUpTransition :duration="animationDuration">
    <div v-show="show"
         :aria-hidden="!show"
         :class="[{'show d-block': show}, {'d-none': !show}, {'modal-mini': type === 'mini'}]"
         class="modal fade"
         role="dialog"
         style="overflow-y: auto;"
         tabindex="-1">

      <div :class="[{'modal-notice': type === 'notice'}, modalClasses]"
           :style="size_style"
           class="modal-dialog">
        <div class="modal-content">

          <div v-if="showHeader"
               :class="headerClasses"
               class="modal-header">
            <slot name="close-button">
              <button v-if="showClose"
                      :aria-hidden="!show"
                      class="close"
                      data-dismiss="modal"
                      type="button"
                      @click="closeModal">
                <i class="nc-icon nc-simple-remove"></i>
              </button>
            </slot>
            <slot name="header"></slot>
          </div>

          <div :class="bodyClasses" class="modal-body">
            <slot></slot>
          </div>

          <div v-if="footerEnable" :class="footerClasses" class="modal-footer">
            <slot name="footer"></slot>
          </div>
        </div>
      </div>

    </div>
  </SlideYUpTransition>
</template>
<script>
import {SlideYUpTransition} from 'vue2-transitions'

export default {
  name: 'modal',
  components: {
    SlideYUpTransition
  },
  props: {
    show: Boolean,
    size: {
      type: String,
      default: 'middle'
    },
    showHeader: {
      type: Boolean,
      default: true
    },
    showClose: {
      type: Boolean,
      default: true
    },
    type: {
      type: String,
      default: '',
      validator(value) {
        let acceptedValues = ['', 'notice', 'mini']
        return acceptedValues.indexOf(value) !== -1
      },
      description: 'Modal type (notice|mini|"") '
    },
    modalClasses: {
      type: [Object, String],
      description: 'Modal dialog css classes'
    },
    headerClasses: {
      type: [Object, String],
      description: 'Modal Header css classes'
    },
    bodyClasses: {
      type: [Object, String],
      description: 'Modal Body css classes'
    },
    footerClasses: {
      type: [Object, String],
      description: 'Modal Footer css classes'
    },
    animationDuration: {
      type: Number,
      default: 500,
      description: 'Modal transition duration'
    },
    footerEnable: {
      type: [Boolean],
      default: true
    }
  },
  data() {
    return {
      size_style: "max-width:1000px",
    }
  },
  methods: {
    closeModal() {
      this.$emit('update:show', false)
      this.$emit('close')
    }
  },
  watch: {
    show(val) {
      let documentClasses = document.body.classList
      if (val) {
        documentClasses.add('modal-open')
      } else {
        documentClasses.remove('modal-open')
      }
    },
  },
  mounted() {
    switch (this.size) {
      case 'xlarge':
        this.size_style = "max-width:1250px";
        break;
      case 'large':
        this.size_style = "max-width:1000px";
        break;
      case 'middle':
        this.size_style = "max-width:750px";
        break;
      case 'small':
        this.size_style = "max-width:500px";
        break;
      default:
        this.size_style = "max-width:750px";
        break;
    }
  }
}
</script>
<style>
.modal.show {
  background-color: rgba(0, 0, 0, 0.75)
}
</style>
