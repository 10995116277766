<template>
  <div
    :data-active-color="activeColor"
    :data-color="backgroundColor"
    class="sidebar">
    <!-- Service logo-->
    <div class="logo">
      <a href="/"><img
        alt=""
        class="s-service-logo"
        height="60px"
        src="https://s3-ap-northeast-1.amazonaws.com/quado-assets/service_logo_white_padding.svg"></a>
    </div>

    <!-- Menu -->
    <div
      ref="sidebarScrollArea"
      class="sidebar-wrapper hide-scroll">
      <slot />
      <ul class="nav">
        <slot name="links">
          <div v-for="(link, index) in sidebarLinks">
            <!-- TODO: refactor -->
            <!-- U2Fの場合はMetadataは表示しない-->
            <sidebar-item
              v-if="isLinkDisplay(link.name)"
              :key="link.name + index"
              :link="link">
              <sidebar-item
                v-for="(subLink, index) in link.children"
                :key="subLink.name + index"
                :link="subLink" />
            </sidebar-item>
          </div>
        </slot>
      </ul>
    </div>
  </div>
</template>
<script>
import 'perfect-scrollbar/css/perfect-scrollbar.css'

export default {
  props: {
    title: {
      type: String,
      default: 'Quado console',
      description: 'Sidebar title'
    },
    backgroundColor: {
      type: String,
      default: 'black',
      validator: (value) => {
        let acceptedValues = ['white', 'brown', 'black']
        return acceptedValues.indexOf(value) !== -1
      },
      description: 'Sidebar background color (white|brown|black)'
    },
    activeColor: {
      type: String,
      default: 'success',
      validator: (value) => {
        let acceptedValues = ['primary', 'info', 'success', 'warning', 'danger']
        return acceptedValues.indexOf(value) !== -1
      },
      description: 'Sidebar active text color (primary|info|success|warning|danger)'
    },
    logo: {
      type: String,
      default: '/static/img/Quado_logo_square.png',
      description: 'Sidebar Logo'
    },
    sidebarLinks: {
      type: Array,
      default: () => [],
      description: 'Sidebar links. Can be also provided as children components (sidebar-item)'
    },
    autoClose: {
      type: Boolean,
      default: true
    }
  },
  provide() {
    return {
      autoClose: this.autoClose
    }
  },
  mounted() {
    this.initScrollBarAsync()
  },
  beforeDestroy() {
    if (this.$sidebar.showSidebar) {
      this.$sidebar.showSidebar = false
    }
  },
  methods: {
    isLinkDisplay(linkName) {
      if (linkName === 'Metadata' && this.$store.state.service === 'u2f') {
        return false
      } else if (linkName === 'Webhooks' && !this.$store.state.selectedPlan.options.webhook) {
        return false
      } else {
        return true
      }
    },
    async initScrollBarAsync() {
      let isWindows = navigator.platform.startsWith('Win');
      if (!isWindows) {
        return;
      }
      const PerfectScroll = await import('perfect-scrollbar')
      PerfectScroll.initialize(this.$refs.sidebarScrollArea)
    }
  }
}

</script>
<style>
.s-service-logo {
  width: 90% !important;
}

@media (min-width: 992px) {
  .navbar-search-form-mobile,
  .nav-mobile-menu {
    display: none;
  }
}

.hide-scroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

</style>
