import axios from 'axios';

/*
  auth0-spa-jsで定義されていないAPIをここで定義する
 */

export default class Auth0Service {

  constructor() {
    this.endpoint = 'https://' + process.env.VUE_APP_AUTH0_DOMAIN;
  }

  // baseURLを変更したいときは、URLにフルパスを指定
  async req() {
    return axios.create({
      baseURL: `${this.endpoint}`,
      timeout: 10000, // ms
      headers: {
        'Content-Type': 'application/json',
      }
    })
  }

  /**
   * passwordの変更要求
   * @param {string} email
   * @returns {Promise<AxiosResponse<any>>}
   */
  async changePassword(email) {
    let params = {
      client_id: process.env.VUE_APP_AUTH0_CLIENTID,
      email: email,
      connection: process.env.VUE_APP_AUTH0_CONNECTION
    }
    return (await this.req()).post(`/dbconnections/change_password`, params)
  }
}
