<template>
  <div :class="{active: checked}"
       class="choice"
       data-toggle="wizard-checkbox"
       @click="updateValue">
    <input :checked="checked"
           :disabled="disabled"
           :name="name"
           type="checkbox">
    <div class="icon">
      <slot name="icon">
        <i :class="icon"></i>
      </slot>
    </div>
    <slot name="title">
      <h6>{{ title }}</h6>
    </slot>
  </div>
</template>
<script>
export default {
  name: 'icon-checkbox',
  model: {
    prop: 'checked'
  },
  props: {
    checked: {
      type: Boolean,
      default: false,
      description: 'Whether is checked'
    },
    name: {
      type: String,
      description: 'Input name'
    },
    title: {
      type: String,
      description: 'Icon checkobx title (text)'
    },
    icon: {
      type: String,
      description: 'clickable icon (acts as checkbox)'
    },
    disabled: {
      type: Boolean,
      description: 'Whether is disabled'
    }
  },
  methods: {
    updateValue() {
      this.$emit('input', !this.checked)
    }
  }
}
</script>
<style>
</style>
