<template>
  <footer class="footer">
    <div class="container-fluid">
      <nav class="pull-left">
        <ul>
          <li>
            <a href="https://doc.quado.io/privacy_policy/ja" rel="noopener" target="_blank">PRIVACY POLICY</a>
          </li>
          <li>
            <a href="https://doc.quado.io/terms_of_use/ja" rel="noopener" target="_blank">TERMS OF USE</a>
          </li>
        </ul>
      </nav>
    </div>
  </footer>
</template>
<script>
export default {}

</script>
<style>

</style>
