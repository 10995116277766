<template>
  <navbar v-model="showNavbar">
    <!-- Create RP Modal -->
    <transition name="fade">
      <p-create-rp
        v-if="showModal"
        :show-close="$store.state.rpList.length !== 0"
        @close="closeRP()" />
    </transition>


    <!-- Select currnet RP-->
    <div class="navbar-wrapper">
      <span class="navbar-brand s-title"><i class="fas fa-cog" /> Management Console </span>
    </div>

    <template slot="navbar-menu">
      <li class="nav-item">
        <a
          class="nav-link"
          href="https://doc.quado.io/"
          target="_blank">
          <p><i class="fas fa-book" /> DOCUMENTS</p>
        </a>
      </li>

      <drop-down
        class="nav-item dropdown"
        direction="none"
        icon="fas fa-server"
        position="right"
        tag="li"
        title="Relying Parties">
        <a
          v-for="rp in $store.state.rpList"
          :key="rp.id + rp.service"
          class="dropdown-item"
          href="#"
          @click="selectRP(rp)">
          <strong><span style="float: left">{{ rp.name }}</span></strong><br>
          <span style="color: #8492a6; font-size: 12px">{{ $store.getters.serviceName(rp.service) }}</span><br>
        </a>
        <a
          class="dropdown-item border-bottom s-create-btn btn-info"
          href="#"
          @click="createRP()"><strong>+ Register new relying party</strong></a>
      </drop-down>


      <drop-down
        :title="$store.state.idTokenClaims.nickname"
        class="nav-item dropdown"
        direction="none"
        icon="fas fa-user"
        position="right"
        tag="li">
        <a
          class="dropdown-item border-bottom"
          href="#"
          @click="goSettings()">Account Settings</a>
        <a
          class="dropdown-item"
          href="#"
          @click="logout()">Logout</a>
      </drop-down>
    </template>
  </navbar>
</template>

<script>
import {Navbar} from '@/components/UIComponents'
import {Input, Option, Select} from 'element-ui'
import CreateRPModal from '@/components/Pages/CreateRPModal'

export default {
  components: {
    Navbar,
    'p-create-rp': CreateRPModal,
    [Select.name]: Select,
    [Option.name]: Option,
    [Input.name]: Input,
  },
  data() {
    return {
      activeNotifications: false,
      showNavbar: false,
      showModal: false,
      currentRP: ''
    }
  },
  methods: {
    logout() {
      localStorage.clear()
      this.$auth.logout({
        returnTo: process.env.VUE_APP_REDIRECT_URL_ON_LOGOUT
      })
    },
    goSettings() {
      this.$router.push({path: '/account'})
    },
    closeDropDown() {
      this.activeNotifications = false
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar)
    },
    toggleNavbar() {
      this.showNavbar = !this.showNavbar;
    },
    createRP() {
      this.showModal = true
    },
    closeRP() {
      this.showModal = false
    },
    selectRP(rp) {
      this.$store.dispatch('selectRPAction', {
        id: rp.id,
        service: rp.service,
        goTop: true,
        noFallback: true
      }).catch(() => {
        this.notifyError('Failed to switch relying party.')
      })
    }
  },
}

</script>
<style lang="scss">
@import "src/assets/sass/paper/_variables.scss";

.s-create-btn {
  color: $white-color !important;
}

.s-title {
  color: $default-color;
  font-weight: bold;
}
</style>
