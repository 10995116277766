export default {
  methods: {
    columnTruncate(n, sym = '...') {
      return (row, column) => {
        if (row[column.property] != null) {
          // n=0のときは、truncateしない
          if (n !== 0) {
            return (row[column.property].length > n) ? row[column.property].substr(0, n) + sym : row[column.property];
          } else {
            return row[column.property]
          }
        } else {
          return "-"
        }
      }
    },

    apiPathTruncate(n) {
      return (row, column) => {
        if (row[column.property] != null) {
          // n=0のときは、truncateしない
          let trimPath = '/' + row[column.property].split("/").slice(4).join("/")
          if (n !== 0) {
            return (trimPath.length > n) ? trimPath.substr(0, n) + '...' : trimPath;
          } else {
            return trimPath
          }
        } else {
          return "-"
        }
      }

    },

    capitalizeFirstLetter(str) {
      return str.slice(0, 1).toUpperCase() + str.slice(1);
    },

    isType(x) {
      return (x !== x) ? "NaN" : (x === Infinity || x === -Infinity) ? "Infinity" : Object.prototype.toString.call(x).slice(8, -1);
    },

    isArray(x) {
      return this.isType(x) === "Array"
    },

    isObject(x) {
      return this.isType(x) === "Object"
    },

    isEmptyObject(x) {
      if (!this.isObject(x)) {
        return false
      } else {
        return !Object.keys(x).length;
      }
    },

    isNumber(x) {
      return this.isType(x) === "Number"
    },

    isString(x) {
      return this.isType(x) === "String"
    }
  }
}
