import VeeValidate from 'vee-validate'
import IsCidr from 'is-cidr'

// Json
VeeValidate.Validator.extend('json', {
  getMessage: field => 'The ' + field + ' value must be json format',
  validate: value => {
    try {
      JSON.parse(value, function (k, v) {
        if (k === "" && typeof v === "number") {
          throw "Invalid JSON";
        }
        return v;
      });
      return true;
    } catch (e) {
      return false;
    }
  }
})

VeeValidate.Validator.extend('webauthTrustedFacets', {
  getMessage: field => 'The ' + field + ' value must be https://FQDN, http://localhost[:port], android:apk-key-hash:ANY_STRING or ios:bundle-id:ANY_STRING',
  validate: value => {
    const app_ids = value.split(/[ ,]+/)
    for (let i = 0; i < app_ids.length; i++) {
      if (!(
        app_ids[i].match(/^http:\/\/localhost(:[0-9]+)?$/) ||
        app_ids[i].match(/^https:\/\/[^/:]+(:\d+)?$/) ||
        app_ids[i].startsWith('android:apk-key-hash:') ||
        app_ids[i].startsWith('ios:bundle-id:')
      )) {return false}
    }
    return true
  }
})


// General Single URL
VeeValidate.Validator.extend('url', {
  validate: value => {
    return !!(
      value.match(/^https?:\/\/[^/:]+(:\d+)?/)
    )
  }
})


// UAF/U2F app ID
VeeValidate.Validator.extend('appId', {
  getMessage: field => 'The ' + field + ' value must start with https://, android:apk-key-hash: or ios:bundle-id:',
  validate: value => {
    return !!(
      String(value).startsWith('https://') ||
      String(value).startsWith('android:apk-key-hash:') ||
      String(value).startsWith('ios:bundle-id:')
    )
  }
})


// Comma separated general URLs
VeeValidate.Validator.extend('urls', {
  validate: value => {
    const app_ids = value.split(/[ ,]+/)
    for (let i = 0; i < app_ids.length; i++) {
      if (!app_ids[i].match(/^https?:\/\/[^/:]+(:\d+)?$/)) {
        return false
      }
    }
    return true
  }
})


// ACL
VeeValidate.Validator.extend('networks', {
  validate: value => {
    const ips = value.split(/[ ,]+/)
    if (ips.length > 16) {
      return false
    }
    for (let i = 0; i < ips.length; i++) {
      if (IsCidr.v4(ips[i]) === false && IsCidr.v6(ips[i]) === false) {
        return false
      }
    }
    return true
  }
})

// domain
VeeValidate.Validator.extend('domain', {
  getMessage: field => 'The ' + field + ' value must be domain or subdomain',
  validate: value => {
    return !!(value.match(/^([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/i) || value === 'localhost')
  }
})

export default VeeValidate
