import axios from 'axios';
import {getInstance} from "@/auth";

export default class RelyingParty {

  constructor(service = "webauthn") {
    this.service = service
    this.endpoint = `${process.env.VUE_APP_API_ENDPOINT}/api/v1`;
  }


  // baseURLを変更したいときは、URLにフルパスを指定
  async req() {
    const accessToken = await getInstance().auth0Client.getTokenSilently()
    return axios.create({
      baseURL: `${this.endpoint}/${this.service}`,
      timeout: 10000, // ms
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      }
    })
  }


  /**
   * 全RPの取得
   * @returns {Promise<AxiosResponse<any>>}
   */
  async listRP() {
    return (await this.req()).get(`${this.endpoint}/relying_parties`)
  }


  /**
   * RPの作成
   * @param {object} params
   * @returns {Promise<AxiosResponse<any>>}
   */
  async createRP(params) {
    return (await this.req()).post(`/relying_parties`, params)
  }

  /**
   * RPの取得
   * @param {string} rp_id
   * @returns {Promise<AxiosResponse<any>>}
   */
  async showRP(rp_id) {
    return (await this.req()).get(`/relying_parties/${rp_id}`)
  }

  /**
   * RPのアップデート
   * @param {string} rp_id
   * @param {object} params
   * @returns {Promise<AxiosResponse<any>>}
   */
  async updateRP(rp_id, params) {
    return (await this.req()).patch(`/relying_parties/${rp_id}`, params)
  }

  /**
   * RPの削除
   * @param {string} rp_id
   * @returns {Promise<AxiosResponse<any>>}
   */
  async deleteRP(rp_id) {
    return (await this.req()).delete(`/relying_parties/${rp_id}`)
  }

  /**
   * API keyの変更
   * @param {string} rp_id
   * @returns {Promise<AxiosResponse<any>>}
   */
  async changeApikey(rp_id) {
    return (await this.req()).patch(`/relying_parties/${rp_id}/api_key`, {})
  }

  /**
   * Dashboardに表示するStatsの取得
   * @param {string} rp_id
   * @returns {Promise<AxiosResponse<any>>}
   */
  async showStats(rp_id) {
    return (await this.req()).get(`/relying_parties/${rp_id}/stats`)
  }

  /**
   * Planの取得
   * @param {string} rp_id
   * @returns {Promise<AxiosResponse<any>>}
   */
  async showPlan(rp_id) {
    return (await this.req()).get(`/relying_parties/${rp_id}/plan`)
  }


  /**
   * ユーザ一覧の取得
   * @param {string} rp_id
   * @param {string} searchQuery
   * @param {integer} page
   * @param {integer} size
   * @returns {Promise<AxiosResponse<any>>}
   */
  async showUsers(rp_id, searchQuery, page, size) {
    let created_range = searchQuery.dateTimeRange || ['', '']
    return (await this.req()).get(`/relying_parties/${rp_id}/users`, {
      params: {
        page: page,
        size: size,
        created_from: created_range[0],
        created_to: created_range[1],
        uid: searchQuery.uid,
      }
    })
  }

  /**
   * ユーザの消去
   * @param {string} rp_id
   * @param {string} uid
   * @returns {Promise<AxiosResponse<any>>}
   */
  async deleteUser(rp_id, uid) {
    return (await this.req()).delete(`/relying_parties/${rp_id}/users/${uid}`)
  }


  /**
   * ユーザのFIDO key一覧
   * @param {string} rp_id
   * @param {string} uid
   * @returns {Promise<AxiosResponse<any>>}
   */
  async showRegisteredKeys(rp_id, uid) {
    return (await this.req()).get(`/relying_parties/${rp_id}/users/${uid}/registered_keys`)
  }

  /**
   * FIDO keyの消去
   * @param {string} rp_id
   * @param {string} uid
   * @param {string} key_id
   * @returns {Promise<AxiosResponse<any>>}
   */
  async deleteRegisteredKey(rp_id, uid, key_id) {
    return (await this.req()).delete(`/relying_parties/${rp_id}/users/${uid}/registered_keys/${key_id}`)
  }


  // Local Metadata操作
  async createLocalMetadata(rp_id, params) {
    return (await this.req()).post(`/relying_parties/${rp_id}/metadata_statements`, params)
  }

  async showLocalMetadata(rp_id, metadata_id) {
    return (await this.req()).get(`/relying_parties/${rp_id}/metadata_statements/${metadata_id}`)
  }

  async updateLocalMetadata(rp_id, metadata_id, params) {
    return (await this.req()).patch(`/relying_parties/${rp_id}/metadata_statements/${metadata_id}`, params)
  }

  async deleteLocalMetadata(rp_id, metadata_id) {
    return (await this.req()).delete(`/relying_parties/${rp_id}/metadata_statements/${metadata_id}`)
  }

  async listLocalMetadata(rp_id) {
    return (await this.req()).get(`/relying_parties/${rp_id}/metadata_statements`)
  }


  // Trusted Metadata操作
  async listTrustedMetadataV2() {
    return (await this.req()).get(`/trusted_statements?schema=2`)
  }

  async listTrustedMetadataV3() {
    return (await this.req()).get(`/trusted_statements?schema=3`)
  }

  // webhooks操作
  async updateWebhooks(rp_id, params) {
    return (await this.req()).patch(`/relying_parties/${rp_id}/web_hooks`, params)
  }

  // ACL操作
  async updateAcl(rp_id, params) {
    return (await this.req()).patch(`/relying_parties/${rp_id}/acl`, params)
  }

  // Log操作
  async showLogs(rp_id, searchQuery, page, size) {
    return (await this.req()).post(`/relying_parties/${rp_id}/logs`, searchQuery, {
      params: {
        page: page,
        size: size,
      }
    })
  }
}
