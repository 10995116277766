import {getInstance} from "./authWrapper";

export const authGuard = (to, from, next) => {
  const authService = getInstance();

  const fn = () => {
    if (authService.isAuthenticated) {
      // RPのロードが完了してからルーティングしないと、component表示に必要な情報がないのでエラーがでるのでチェックを行う
      return authService.rpCheck(to, from, next)
    } else {
      authService.loginWithRedirect({appState: {targetUrl: to.fullPath}});
    }
  };

  if (!authService.authLoading) {
    return fn();
  }

  authService.$watch("authLoading", authLoading => {
    if (authLoading === false) {
      return fn();
    }
  });

};
