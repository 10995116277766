import Vue from 'vue'
// import '@babel/polyfill'
import './pollyfills'
import lang from 'element-ui/lib/locale/lang/en'
import locale from 'element-ui/lib/locale'
import App from './App.vue'
import sidebarLinks from './sidebarLinks'
import initProgress from './progressbar'
import router from './routes/router'
import store from './store/store'


// CSS
import 'vuejs-dialog/dist/vuejs-dialog.min.css'
import './assets/sass/paper-dashboard.scss'
import './assets/sass/demo.scss'
import './assets/sass/workaround.scss'


// Standard JS library
import Bowser from 'bowser'
import {sync} from 'vuex-router-sync'


// Plugins
import GlobalComponents from './globalComponents'
import GlobalDirectives from './globalDirectives'
import SideBar from './components/Layout/Sidebar'
import VuejsDialog from 'vuejs-dialog'
import Notifications from 'vue-notification'
import VeeValidate from './validators'
import VueAlert from '@vuejs-pt/vue-alert'
import moment from 'vue-moment-tz'
import VueLocalStorage from 'vue-localstorage'
import VTooltip from 'v-tooltip'
import VueAnalytics from 'vue-analytics';
import {Auth0Plugin} from "./auth";
import VueHighlightJS from 'vue-highlight.js'
import json from 'highlight.js/lib/languages/json';
import 'highlight.js/styles/atom-one-dark-reasonable.css';
import VueUid from 'vue-uid';
// Global Mixins
import Processing from 'src/mixins/processing'
import Notify from 'src/mixins/notify'
import Filter from 'src/mixins/filter'
import Utils from 'src/mixins/utils'

Vue.use(GlobalDirectives)
Vue.use(GlobalComponents)
Vue.use(SideBar, {sidebarLinks: sidebarLinks})
Vue.use(VuejsDialog, {cancelText: 'Cancel', okText: 'OK', html: true})
// Vue.use(VeeValidate, {delay: 1000})
Vue.use(Notifications)
Vue.use(VeeValidate)
Vue.use(VueAlert)
Vue.use(moment)
Vue.use(VueLocalStorage)
Vue.use(VTooltip)
Vue.use(VueAnalytics, {id: 'UA-139550049-2', router})
Vue.use(VueUid);

Vue.use(Auth0Plugin, {
  domain: process.env.VUE_APP_AUTH0_DOMAIN,
  clientId: process.env.VUE_APP_AUTH0_CLIENTID,
  audience: process.env.VUE_APP_AUTH0_AUDIENCE,
  scope: process.env.VUE_APP_AUTH0_SCOPE,
  redirectUri: process.env.VUE_APP_AUTH0_REDIRECT_URI,
  store: store,
  router: router
});

Vue.use(VueHighlightJS, {
  languages: {
    json
  }
})

Vue.mixin(Processing)
Vue.mixin(Notify)
Vue.mixin(Filter)
Vue.mixin(Utils)


// Local setting
locale.use(lang)


// ブラウザの判別
// NG: Mobile, IE
const browser = Bowser.getParser(window.navigator.userAgent)
const browserCheckNG =
  browser.getBrowserName() === 'Internet Explorer' ||
  browser.getPlatform().type === 'mobile'


initProgress(router)
router.beforeEach((to, from, next) => {
  // Browser Check NG
  if (browserCheckNG) {
    if (to.path !== '/not_supported_browser') {
      return next({path: '/not_supported_browser'})
    } else {
      return next()
    }
  }
  return next()
})

// Sync Vuex store and router
sync(store, router)

/* eslint-disable no-new */
new Vue({
  el: '#app',
  render: h => h(App),
  router,
  store
})
