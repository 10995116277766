export default [
  {
    name: 'Dashboard',
    icon: 'fa fa-tachometer-alt',
    path: '/dashboard'
  },
  {
    name: 'Configurations',
    icon: 'fas fa-cogs',
    path: '/configurations'
  },
  {
    name: 'Registered Users',
    icon: 'fa fa-users',
    path: '/users'
  },
  {
    name: 'Metadata',
    icon: 'fa fa-database',
    children: [
      {
        name: 'Local Metadata',
        path: '/metadata'
      },
      {
        name: 'Trusted Metadata',
        path: '/trusted_metadata'
      }]
  },
  {
    name: 'Webhooks',
    icon: 'fas fa-globe',
    children: [
      {
        name: 'Registration Webhook',
        path: '/reg_webhooks'
      },
      {
        name: 'Authentication Webhook',
        path: '/auth_webhooks'
      }]
  },
  {
    name: 'API Logs',
    icon: 'fas fa-book-open',
    path: '/logs'
  },
  {
    name: 'Security',
    icon: 'fas fa-shield-alt',
    path: '/security'
  },
  {
    name: 'Plans',
    icon: 'fa fa-check-circle',
    path: '/plan'
  }
]
