import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/routes/router'
// Service
import RelyingParty from '@/services/relyingParty'
import UserService from '@/services/user'
import Auth0Service from '@/services/auth0'

Vue.use(Vuex)

export default new Vuex.Store({
  // plugins: [createPersistedState({storage: window.sessionStorage, key: 'quado_console'})],
  state: {
    // ログイン中のユーザのIdTokenのクレーム(sub等)
    idTokenClaims: {},
    // ログイン中のユーザ情報(mail/name/about等)
    userMetadata: {},
    // MFAデバイスリスト
    mfaDevices: [],
    // UAF/U2F/WebauthnのすべてのRPのリスト
    rpList: [],
    // 選択中のRPの詳細情報。
    // component/Layout/Comtent.vueのrouter-viewのkeyでselectedRP.idを指定しているため、パラメータ変更時は自動的に再ルーティングされる
    selectedRP: {},
    selectedPlan: {},
    // Dashboardのheatmapの値
    dashboard: {
      heatMapAuthentication: [],
      heatMapRegistration: [],
    },
    // 以下の2つは、サービスが切り替わるタイミングで更新すること
    service: "", // uaf/u2f/webauthn
    rpService: new RelyingParty(),
    userService: new UserService(),
    auth0Service: new Auth0Service()
  },
  mutations: {
    changeIdTokenClaims(state, newValue) {
      state.idTokenClaims = newValue
    },
    changeMetadata(state, newValue) {
      state.userMetadata = newValue
    },
    changeMfaState(state, newValue) {
      state.userMetadata.use_mfa = newValue
    },
    // サービスの切り替えを行う
    changeService(state, newValue) {
      state.service = newValue
      state.rpService = new RelyingParty(newValue)
    },
    updateMfaDevices(state, newValue) {
      state.mfaDevices = newValue
    },
    updateRPList(state, newValue) {
      state.rpList = newValue
    },
    updateRP(state, newValue) {
      state.selectedRP = newValue
    },
    updatePlan(state, newValue) {
      state.selectedPlan = newValue
    },
    updateAPIKey(state, newValue) {
      state.selectedRP.api_key = newValue
    },
    updateDashboard(state, newValue) {
      state.dashboard = newValue
    },
  },
  getters: {
    rpList: state => () => state.rpList,
    serviceName: state => (service) => {
      switch (service) {
        case 'uaf':
          return 'FIDO UAF 1.1'
        case 'u2f':
          return 'FIDO U2F 1.1'
        case 'webauthn':
          return 'FIDO2/Webauthn 2.0'
      }
    },
  },
  actions: {

    //
    //
    // 以下、サービスの切り替えが発生するため、changeServiceを呼び出すこと
    //
    //


    // 1. サービスを更新
    // 2. サーバからRP情報を取得
    // 2.1 削除等によりRPが取得できない場合は最初のRPを選択
    // 3. プランを取得
    // 4. state(selectedRP)を更新
    // 5. "/"にもどす
    /**
     *
     * @param ctx
     * @param params
     * @param {string} params.id rp_id
     * @param {string} params.service uaf|u2f|webauthn
     * @param {boolean} params.noFallback fallback first rp if error
     * @param {boolean} params.goTop
     * @returns {Promise<AxiosResponse<*>[]>}
     */
    selectRPAction(ctx, params) {

      // RPの変更が成功するまでは、storeのサービスを変更しない
      let rpService = new RelyingParty(params.service)
      return Promise.all([rpService.showRP(params.id), rpService.showPlan(params.id)])
        .then(response => {
          const rp = response[0]
          const plan = response[1]
          // サービスを更新
          ctx.commit('changeService', params.service)
          // RP,Planの情報を更新
          ctx.commit('updateRP', rp.data)
          ctx.commit('updatePlan', plan.data)
          // リロード時に元のRP画面に戻れるように対応
          localStorage.setItem('id', params.id)
          localStorage.setItem('service', params.service)
          // goTopフラグが指定されていたら、画面をDashboardに移動
          if (params.goTop) {
            // 同一pathに遷移するとエラーが発生し、画面が表示されないので無効化する
            return router.push({path: '/dashboard'}).catch(() => {
            })
          }
        })
        .catch(() => {
          if (!!params.noFallback) {
            return Promise.reject("Failed to get plan")
          } else if (ctx.state.rpList.length > 0) {
            // RPの情報が取得できない場合は、先頭のRPを選択
            return ctx.dispatch('selectRPAction', {
              id: ctx.state.rpList[0].id,
              service: ctx.state.rpList[0].service,
              noFallback: true
            })
          }
        })
    },


    // 1. サーバにRPを登録
    // 2. RP listのupdate
    // 3. 新規に作成したRPを選択
    /**
     *
     * @param ctx
     * @param params
     * @param {string} params.service
     * @param {object} params.rpParams
     * @returns {Promise<*>}
     */
    createRPAction(ctx, params) {
      let rpInfo

      let rpService = new RelyingParty(params.service)
      return rpService.createRP(params.rpParams)
        .then(response => {
          rpInfo = response.data
          return ctx.dispatch('updateRPListAction')
        })
        .then(() => {
          return ctx.dispatch('selectRPAction', {id: rpInfo.id, service: params.service, goTop: true})
        }).then(() => {
          return rpInfo
        })
        .catch((error) => {
          return Promise.reject(error)
        });
    },


    // 1. サーバからRPの消去
    // 2. RP listのupdate
    // 3. RP listの先頭のRPを選択
    deleteRPAction(ctx, id) {
      return ctx.state.rpService.deleteRP(id)
        .then(() => {
          return ctx.dispatch('updateRPListAction')
        })
        .then(() => {
          if (ctx.state.rpList.length > 0) {
            return ctx.dispatch('selectRPAction', {
              id: ctx.state.rpList[0].id,
              service: ctx.state.rpList[0].service,
              goTop: true
            })
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        });
    },


    //
    // 以下、サービスの切り替えは発生しない
    //

    updateDashboard(ctx) {
      return ctx.state.rpService.showStats(ctx.state.selectedRP.id)
        .then((response) => {
          ctx.commit('updateDashboard', {
            heatMapAuthentication: response.data.authentications,
            heatMapRegistration: response.data.registrations
          })
          // ユーザ数はRP情報に含まれる
          return ctx.state.rpService.showRP(ctx.state.selectedRP.id)
        }).then(response => {
          ctx.commit('updateRP', response.data)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    updateRPListAction(ctx) {
      return ctx.state.rpService.listRP()
        .then(response => {
          ctx.commit('updateRPList', response.data)
          return response
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },


    // 1. サーバのRPをアップデート
    updateRPAction(ctx, params) {
      let rpInfo

      return ctx.state.rpService.updateRP(params.id, params)
        .then(response => {
          ctx.commit('updateRP', response.data)
          rpInfo = response
          return ctx.dispatch('updateRPListAction')
        })
        .then(() => {
          return rpInfo
        })
        .catch(error => {
          return Promise.reject(error)
        });
    },


    // 1. API keyをアップデート
    // 2. state(selectedRP)を更新
    changeAPIKeyAction(ctx, id) {
      return ctx.state.rpService.changeApikey(id)
        .then(response => {
          ctx.commit('updateAPIKey', response.data.api_key)
          return response
        })
        .catch(error => {
          return Promise.reject(error)
        });
    },


    // 1. Webhookをアップデート
    // 2. RPの情報をstoreへpush
    updateWebhooksAction(ctx, params) {
      return ctx.state.rpService.updateWebhooks(params.id, params.hooks)
        .then(response => {
          ctx.commit('updateRP', response.data)
          return response
        })
        .catch(error => {
          return Promise.reject(error)
        });
    },

    // 1. ACLをアップデート
    // 2. RPの情報を取得し、storeへpush
    updateAclAction(ctx, params) {
      return ctx.state.rpService.updateAcl(params.id, params.acl)
        .then(response => {
          ctx.commit('updateRP', response.data)
          return response
        })
        .catch(error => {
          return Promise.reject(error)
        });
    },


    //
    // 以下、account操作関連
    //

    // user metadataをアップデート
    updateUserMetadata(ctx, params) {
      return ctx.state.userService.updateProfile(params)
        .then(response => {
          ctx.commit('changeMetadata', response.data.user_metadata)
          return response.data
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    // MFAの利用有無の変更(user metadataのuse_mfaのtrue/false切り替え)
    updateMfaState(ctx, enable) {
      return ctx.state.userService.updateProfile({use_mfa: enable})
        .then(response => {
          ctx.commit('changeMfaState', response.data.user_metadata.use_mfa)
          return response.data
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    // MFAデバイスリストを更新する
    getMfaDevices(ctx) {
      return ctx.state.userService.getAuthenticator()
        .then(response => {
          ctx.commit('updateMfaDevices', response.data)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    // MFAデバイスを消去する
    deleteMfaDevice(ctx) {
      return ctx.state.userService.deleteAuthenticator()
        .then(() => {
          return ctx.dispatch('updateMfaState', false)
        })
        .then(() => {
          return ctx.dispatch('getMfaDevices')
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },
  }
})
