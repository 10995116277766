import Vue from "vue";
import createAuth0Client from "@auth0/auth0-spa-js";

let instance;

export const getInstance = () => instance;

export const useAuth0 = ({
                           store = options.store,
                           router = options.router,
                           ...options
                         }) => {
  if (instance) return instance;

  instance = new Vue({

    data() {
      return {
        authLoading: true,
        rpLoading: true,
        isAuthenticated: false,
        user: {},
        auth0Client: null,
        popupOpen: false,
        error: null,
      };
    },

    async created() {
      this.auth0Client = await createAuth0Client({
        domain: options.domain,
        client_id: options.clientId,
        audience: options.audience,
        scope: options.scope,
        redirect_uri: options.redirectUri
      });

      try {
        // URLにcodeとstateが存在するときは、tokenと交換
        if (window.location.search.includes("code=") && window.location.search.includes("state=")) {
          await this.auth0Client.handleRedirectCallback();
        }
        // 認証成功の判断
        this.isAuthenticated = await this.auth0Client.isAuthenticated();
        if (!this.isAuthenticated) {
          return
        }
        // ユーザが存在しなければ作成する
        await store.state.userService.showUser()
        // StoreにidTokenClaimsを保存
        const idTokenClaims = await this.auth0Client.getIdTokenClaims()
        store.commit('changeIdTokenClaims', idTokenClaims);
        // 初回起動を高速化するために、idTokenにuser_metadataを含めるた
        store.commit('changeMetadata', idTokenClaims['https://quado.io/user_metadata'])
      } catch (e) {
        this.error = e;
      } finally {
        this.authLoading = false;
      }
    },


    methods: {
      async rpCheck(to, from, next) {

        if (!this.rpLoading) {
          return next()
        }

        await store.dispatch('updateRPListAction')
        if (store.state.rpList.length > 0) {
          const rp_id = localStorage.getItem("id")
          const service = localStorage.getItem("service")
          if (rp_id) {
            // RPがloading(Auth+RP取得)が完了するまでは、各componentを表示するとエラーが起きるので、noRoutingを有効にして、別途ルーティングを行う
            await store.dispatch('selectRPAction', {id: rp_id, service: service})
          } else {
            await store.dispatch('selectRPAction', {
              id: store.state.rpList[0].id,
              service: store.state.rpList[0].service
            })
          }
          next()
        } else {
          if (to.path !== '/startup') {
            next('/startup')
          }
        }

        this.rpLoading = false

        // RP Listが空になれば、startupにリダイレクト
        store.watch(store.getters.rpList, rpList => {
          if (rpList.length === 0) {
            router.push("/startup");
          }
        })
      },
      loginWithRedirect(o) {
        return this.auth0Client.loginWithRedirect(o);
      },
      getIdTokenClaims(o) {
        return this.auth0Client.getIdTokenClaims(o);
      },
      getTokenSilently(o) {
        return this.auth0Client.getTokenSilently(o);
      },
      logout(o) {
        return this.auth0Client.logout(o);
      }
    }
  });

  return instance;
};

export const Auth0Plugin = {
  install(Vue, options) {
    Vue.prototype.$auth = useAuth0(options);
  }
};
