import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import VueRouterPrefetch from 'vue-router-prefetch'

Vue.use(VueRouter)
Vue.use(VueRouterPrefetch)

export default new VueRouter({
  mode: 'history',
  routes, // short for routes: routes
  linkActiveClass: 'active',
  scrollBehavior: (to) => {
    if (to.hash) {
      return {selector: to.hash}
    } else {
      return {x: 0, y: 0}
    }
  }
})
